<template>
  <div class="sonContent">
    <div class="SearchBar">
      <div style="display: flex">
        <span style="margin: 6px 10px 0 10px">工程编号/名称:</span>
        <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.projectStr"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">第三方施工队：</span>
        <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.teamName"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">现场管理员：</span>
        <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
        ></el-input>
        <el-select
            v-model="searchBox.timeType"
            style="width: 100px;margin: 0 10px"
            placeholder="请选择"
            size="small"
        >
          <el-option
              v-for="item in tuiliaoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
            size="small"
            v-model="time"
            type="datetimerange"
            @change="timeChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="选择开始日期时间"
            end-placeholder="选择结束日期时间"
        >
        </el-date-picker>
        <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            style="margin-left: 10px"
        >查询</el-button
        >
        <el-button @click="download"  size="small" type="success">下载导入模板</el-button>

      </div>
    </div>
    <!--数据表格-->
    <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%; margin: 15px 0"
      border
      height="calc(100vh - 378px)"
      :stripe="true"
    >
      <el-table-column type="index" label="序号" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="projectNumber"
        label="工程编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="projectName"
        label="工程名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="第三方施工队"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteManager"
        label="现场管理员"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        :prop="hanleBtn == 1?'applyName':'returnName'"
        label="提交人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
          :prop="hanleBtn == 1?'applyTime':'returnTime'"
        label="提交时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="warehouseKeeper"
        label="库管审核人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="warehouseKeeperTime"
        label="库管审核时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="getReceiveApplyInfo(row)"
            >审核详情</el-button
          >
          <el-button
            v-if="hanleBtn == 1"
            type="success"
            size="small"
            @click="onExcel(row, 4)"
            >发料</el-button
          >

          <el-button
            v-if="hanleBtn == 2 || hanleBtn == 3"
            type="success"
            size="small"
            @click="onExcel(row, 4)"
            >退料</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[ 30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      @close="closeFrom('form')"
    >
      <el-form ref="form" :model="form" label-width="80px" :rules="rule">
        <el-form-item label="是否需要退料" prop="archiveNumber">
          <el-select
            v-model="searchBox.timeType"
            style="width: 100px; margin: 0 10px"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in chargeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附加描述" prop="archiveNumber">
          <el-input type="textarea" v-model="form.archiveNumber"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumit('form')">确 定</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      title="审核详情"
      :visible.sync="detildialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeFrom('form')"
    >
      <div style="max-height: 600px; overflow: auto">
        <p class="detil-code">申请信息</p>
        <div style="padding: 10px">
          <div style="line-height: 50px">工程名称：{{ project.projectName }}</div>
          <div style="line-height: 50px">第三方施工队：{{ project.teamName }}</div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1" v-if="hanleBtn != 1">退料方式：{{ project.returnMode }}</div>
            <div style="flex: 1" v-if="hanleBtn == 3">调拨工程：{{ project.returnTo }}</div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">提交人：{{ project.realname }}</div>
            <div style="flex: 1">提交时间：{{ project.createTime }}</div>
          </div>
        </div>
        <p class="detil-code">库房管理审核信息</p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px">
            <div >
              <span style="margin-right: 15px">
                {{ hanleBtn == 1 ? "实际领料单据：" : "实际退料单据：" }}</span
              >
              <div v-if="project.billUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.billUrl.split(',')[0]"
                  :preview-src-list="project.billUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.billUrl.split(",").length }}张</span
                >
              </div>
            </div>
            <div  v-if="hanleBtn == 1">
              <span style="margin-right: 15px">领料照片：</span>
              <div v-if="project.receivePhotoUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.receivePhotoUrl.split(',')[0]"
                  :preview-src-list="project.receivePhotoUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.receivePhotoUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">附加描述:{{ project.remarks }}</div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">审核人：{{ project.warehouseKeeper }}</div>
            <div style="flex: 1">
              审核时间：{{ project.warehouseKeeperTime }}
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detildialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="hanleBtn == 2?'退料':'发料'"
      :visible.sync="excelVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeFrom('form')"
    >
      <div style="max-height: 550px; overflow: auto">
        <p class="detil-code">库房管理审核信息</p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px">
            <div >
              <span style="margin-right: 15px">
                {{ hanleBtn == 1 ? "实际领料单据：" : "实际退料单据：" }}</span
              >
              <div v-if="project.billUrl">
                <el-image
                    style="width: 100px; height: 100px"
                    :src="project.billUrl.split(',')[0]"
                    :preview-src-list="project.billUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                >共{{ project.billUrl.split(",").length }}张</span
                >
              </div>
            </div>
            <div  v-if="hanleBtn == 1">
              <span style="margin-right: 15px">领料照片：</span>
              <div v-if="project.receivePhotoUrl">
                <el-image
                    style="width: 100px; height: 100px"
                    :src="project.receivePhotoUrl.split(',')[0]"
                    :preview-src-list="project.receivePhotoUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                >共{{ project.receivePhotoUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">附加描述:{{ project.remarks }}</div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">审核人：{{ project.warehouseKeeper }}</div>
            <div style="flex: 1">
              审核时间：{{ project.warehouseKeeperTime }}
            </div>
          </div>
        </div>
        <p class="detil-code">库房{{hanleBtn == 2?'退料':'发料'}}信息</p>
        <div style="display: flex;margin-top: 20px">
          <span>导入excel文档：</span>
          <el-upload
            class="upload-demo"
            ref="upload"
            :http-request="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept=".xls,.xlsx"
            action="#"
            :auto-upload="true"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary">导入excel</el-button>
            <!-- <el-button
              style="margin-left: 10px"
              size="small"
              type="success"
              :disabled="!fileData"
              @click="submitUpload"
              >导入</el-button
            > -->
            <div slot="tip" class="el-upload__tip">只能上传excel文件</div>
          </el-upload>
        </div>
        <el-table
          border
          v-if="tableDataList.length"
          :data="tableDataList"
          height="300"
        >
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
          <el-table-column prop='materialCode' label="材料编码" width="80"> </el-table-column>
          <el-table-column prop="materialName" label="材料名称" >
          </el-table-column>
          <el-table-column prop="materialStyle" label="规格型号" >
          </el-table-column>
          <el-table-column prop="unit" label="主计量单位" width="100">
          </el-table-column>
          <el-table-column prop="amount" label="数量" width="60"> </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align: center">
          <el-button style="margin-right: 10px" @click="excelVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            v-if="tableDataList.length"
            @click="submitUpload"
            >提交</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  provideWarehouseList,
  receiveApplyInfo,
  materialImport,
  materialImportSave,
  directWarehouseList,
  returnApplyInfo,
  allotWarehouseList,
} from "../../../RequestPort/cost/cost.js";
import getNowFormatDate from "./../../../common/js/nowDate.js";
export default {
  name: "dataTabel",
  props: {
    hanleBtn: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      tuiliaoList: [
        { label: "提交时间", value: 1 },
        { label: "库管审核时间", value: 2 },
      ],
      dialogVisible: false,
      detildialogVisible: false,
      excelVisible: false,
      searchBox: {
        current: 1,
        size: 100,
        timeType:1
      },
      fileList: [],
      fileData: null,
      project: {
        billUrl:''
      },
      chargeList: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      FileFormData: new FormData(),
      FilefileList: [],
      form: {
        archiveNumber: "",
        pcBackupsUrl: "",
        type: 8,
      },
      rule: {
        archiveNumber: [
          { required: true, message: "请输入附加描述", trigger: "blur" },
        ],
      },
      total: null,
      tableData: [],
      time: [],
      tableDataList: [],
      applyId: null,
    };
  },
  mounted() {
    this.searchBox.keyClass = this.hanleBtn;
    this.loadList();
  },
  methods: {
    search() {
      // if(this.searchBox.time.length>0){
      //   this.searchBox.startTime = this.searchBox.time[0]
      //   this.searchBox.endTime = this.searchBox.time[1]
      // }
      this.loadList();
    },
    timeChange(val) {
      if (val) {
        this.searchBox.startTime = val[0];
        this.searchBox.endTime = val[1];
      } else {
        this.searchBox.startTime = null;
        this.searchBox.endTime = null;
      }
    },
    getReceiveApplyInfo(row) {
      let { hanleBtn } = this;
      if (hanleBtn == 1) {
        receiveApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
          this.detildialogVisible = true;
        });
      } else if (hanleBtn == 2 || hanleBtn == 3) {
        returnApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
          this.returnMode = row.returnMode;
          this.detildialogVisible = true;
        });
      }
    },
    onExcel(row) {
      let { hanleBtn } = this;
      if (hanleBtn == 1) {
        receiveApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
        });
      } else if (hanleBtn == 2 || hanleBtn == 3) {
        returnApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
          this.returnMode = row.returnMode;
        });
      }
      this.tableDataList = [];
      this.excelVisible = true;
      this.applyId = row.id;
    },
    turnMenu(row) {
      this.dialogVisible = true;
    },
    /**
     * 移除文件后的钩子
     */
    handleRemove(file, fileList) {
      this.tableDataList = []
    },
    async handlePreview(file) {
      let uploadObj = new FormData();
      uploadObj.append("file", file.file);
      try {
        let { data } = await materialImport(uploadObj);
        this.tableDataList = data;
      } catch (e) {}
    },
    async submitUpload() {
      let { tableDataList, applyId } = this;
      try {
        let res = await materialImportSave({
          listExcel: tableDataList,
          applyId,
        });
        console.log(res);
        this.tableDataList = [];
        this.excelVisible = false;
        this.$refs.upload.clearFiles();
        this.loadList();
      } catch (e) {}
    },
    loadList() {
      let { hanleBtn } = this;
      if (hanleBtn == 1) {
        provideWarehouseList(this.searchBox).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      } else if (hanleBtn == 2) {
        directWarehouseList(this.searchBox).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      } else if (hanleBtn == 3) {
        allotWarehouseList(this.searchBox).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      }
    },
    /**
     * 下载导入模板
     */
    download(){
      const elink = document.createElement("a");

      elink.href ="https://gas-dev.oss-cn-beijing.aliyuncs.com/ZYMenuIcon/wuliao.xlsx";
      const fileName = getNowFormatDate() + ".xls";

      elink.setAttribute("download", fileName);

      elink.style.display = "none";

      document.body.appendChild(elink);

      setTimeout(() => {
        elink.click();

        document.body.removeChild(elink);
      }, 66);
    },
    // sumit(form) {
    //   this.$refs[form].validate((valid) => {
    //     if (!valid) return false;
    //     updateType(this.form).then((res) => {
    //       if (res.code === 200) {
    //         this.$message.success(res.data.msg);
    //         this.loadList();
    //       } else {
    //         this.$message.error(res.data.msg);
    //       }
    //     });
    //   });
    // },
    closeFrom(form) {
      // this.$refs[form].clearValidate();
      this.FilefileList = [];
      this.form.pcBackupsUrl = "";
      this.form.archiveNumber = "";
      this.$refs.upload.clearFiles()
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList();
    },
  },
};
</script>

<style scoped>
.SearchBar {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
</style>